import React from 'react';

function Home({ pirateMode }) {
  if (pirateMode) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <h1>🏴‍☠️ Welcome to the Pirate Portal 🏴‍☠️</h1>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li><a href="https://ssltool.serso.be">⚓ SSL TOOL ⚓</a></li>
          <li><a href="https://dnstool.serso.be">🏴 DNS TOOL 🏴</a></li>
          <li><a href="http://emailtool.serso.be">🦜 Email Tool 🦜</a></li>
        </ul>
        <img src="https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExdm5pY3BjM3p2MnpmZ3Q5NzJ3cWxoeWYwdmN0NnAwMXlnNTE1NG43dyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/LUIvcbR6yytz2/giphy.gif" alt="Pirate" style={{ maxWidth: '100%' }} />
      </div>
    );
  } else {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <h1>Welcome to the Tools Portal</h1>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li><a href="https://ssltool.serso.be">SSL TOOL</a></li>
          <li><a href="https://dnstool.serso.be">DNS TOOL</a></li>
          <li><a href="http://emailtool.serso.be">Email Tool</a></li>
        </ul>
      </div>
    );
  }
}

export default Home;
