import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './Home';

function App() {
  const [pirateMode, setPirateMode] = useState(false);
  const [inputSequence, setInputSequence] = useState('');

  useEffect(() => {
    const handleKeyPress = (event) => {
      setInputSequence((prev) => (prev + event.key).slice(-6)); // Only keep the last 6 characters
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (inputSequence.toLowerCase() === 'pirate') {
      setPirateMode(true);
    }
  }, [inputSequence]);

  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Home pirateMode={pirateMode} />} />
        {/* Add additional routes here if needed */}
      </Switch>
    </Router>
  );
}

export default App;
